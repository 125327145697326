import { render, staticRenderFns } from "./Serve.vue?vue&type=template&id=6902c551&scoped=true&"
var script = {}
import style0 from "./Serve.vue?vue&type=style&index=0&id=6902c551&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6902c551",
  null
  
)

export default component.exports